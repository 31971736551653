.chat {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chat__messages {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    border-top: 2px solid #2C2C2C;
    padding: 5px 0;
    overflow-y: scroll;
}

.chat__messages :nth-child(even) {
    background-color: #EEEEEE;
}

.chat__message {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 5px;
}

.chat__message__author {
    font-family: 'Open Sans', sans-serif;
    color: #616161;
    font-weight: bold;
}

.chat__submit {
    width: 100%;
    display: flex;
    flex-direction: row nowrap;
    justify-content: center;
}

.chat__submit__input {
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding: 10px;
    color: #000000;
    font-size: 16px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    height: auto;
    margin: 5px;
    max-width: 1080px;
}

.chat__submit__button {
    width: 100px;
    background: none;
    background-color: rgba(255, 255, 255, .2);
    border: none;
    border-radius: 10px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
}

.chat__submit__button:hover {
    background-color: rgba(255, 255, 255, .5);
}