@import url(https://fonts.googleapis.com/css?family=Arvo|Open+Sans&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  color: #FAFAFA;
  font-family: 'Arvo', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  min-height: 100vh;
}

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 85%;
}

.button--default {
  width: 100%;
  max-width: 500px;
  margin: 15px 0;
  height: 55px;
  border: none;
  background-color: #2C2C2C;
  border-radius: 4px;
  color: #FAFAFA;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
}

button {
  outline: none;
}

.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  font-size: 32px;
}

.title h2 {
  margin: 0;
}

h2, h3 {
  text-align: center;
}

.title h1 {
  font-size: 70px;
  margin: 0;
  letter-spacing: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 85%;
  margin-top: 10px;
  flex: 1 1;
}

input {
  border: none;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  max-width: 500px;
  margin: 5px 0 15px 0;
  color: #2C2C2C;
  padding: 0 10px;
  font-size: 2rem;
  text-align: center;
}

select {
  border: none;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  margin: 5px 0 15px 0;
  color: #2C2C2C;
  padding: 0 10px;
  font-size: 2rem;
  text-align: center;
}

option {
  color: #2C2C2C;
  font-family: 'Arvo', serif;
}

input:focus {
  outline-width: 0;
}

label {
  font-size: 1.2rem;
}

.--join {
  background-color: #478BBC;
}

.--lobby {
  background-color: #A266EE;
}

.--results {
  background-color: #A266EE;
}

.--round {
  background-color: #BC4771;
}

.--vote {
  background-color: #478BBC;
}

.back {
  cursor: pointer;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 30px solid #FAFAFA;
  position: absolute;
  top: 15px;
  left: 15px;
}

.alert {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background-color: #FAFAFA;
  color: #000000;
  height: 75px;
  width: 90%;
  margin: auto 0;
}

.max-sm {
  max-width: 480px;
}

.results__form-group {
  margin: 0 10px;
}
.--splash {
    background-color: #EE7866;
}

.brand {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FAFAFA;
    font-family: 'Arvo', serif;
    width: 100%;
    height: 75%;
}
.--create {
    background-color: #47BC99;
}
.lobby__players {
    width: 100%;
    flex: 1 1;
    justify-content: center;
    align-items: center;
}

.lobby__players h2 {
    text-align: center;
    margin: 15px 0;
}

.lobby__info {
    text-align: center;
}
.chat {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chat__messages {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    border-top: 2px solid #2C2C2C;
    padding: 5px 0;
    overflow-y: scroll;
}

.chat__messages :nth-child(even) {
    background-color: #EEEEEE;
}

.chat__message {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 5px;
}

.chat__message__author {
    font-family: 'Open Sans', sans-serif;
    color: #616161;
    font-weight: bold;
}

.chat__submit {
    width: 100%;
    display: flex;
    flex-direction: row nowrap;
    justify-content: center;
}

.chat__submit__input {
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding: 10px;
    color: #000000;
    font-size: 16px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    height: auto;
    margin: 5px;
    max-width: 1080px;
}

.chat__submit__button {
    width: 100px;
    background: none;
    background-color: rgba(255, 255, 255, .2);
    border: none;
    border-radius: 10px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
}

.chat__submit__button:hover {
    background-color: rgba(255, 255, 255, .5);
}
.round__topic {
    display: grid;
    width: 100%;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 20% 20% 20% 20% 20%;
    justify-content: center;
    grid-template-areas: "header header header header";
    background-color: #FAFAFA;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.round__topic__title {
    grid-area: header;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.round__topic__word {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 5px;
    color: #000000;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    background-color: #FAFAFA;
    font-size: 12px;
}

.round__topic__word:nth-child(8n+2),
.round__topic__word:nth-child(8n+4),
.round__topic__word:nth-child(8n+7),
.round__topic__word:nth-child(8n+9),
.round__topic__word:nth-child(8n+10),
.round__topic__word:nth-child(8n+12),
.round__topic__word:nth-child(8n+15),
.round__topic__word:nth-child(8n+17) {
    background-color:#E0E0E0;
}

.highlighted {
    background-color: #45FFFF !important;
}

.vote__info__container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.vote__info {
    min-width: 150px;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-radius: 4px;
    padding: 5px;
    transition: all .1s ease-in-out;
}

.vote__info:hover {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    box-shadow: 0 4px 5px rgba(0, 0, 0, .5);
    cursor: pointer;
}

.vote__info__selected {
    background-color: rgb(142, 235, 207);
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    box-shadow: 0 4px 5px rgba(0, 0, 0, .5);
}


.vote__info__playername {
    color: #777777;
}

.vote__info__playeranswer {
    font-family: 'Open Sans', sans-serif;
    color: #000000;
    font-size: 22px;
    
}

.--hidden {
    display: none;
}

.vote__selection-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 35px;
}

.vote__selection-box>div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.vote__selection--enabled {
    background-color: #47BC99;
    width: 50%;
}

.vote__selection--disabled {
    background-color: rgb(142, 235, 207);
    width: 50%;
    color: rgb(134, 218, 192);
}

.vote__selection--disabled:hover {
    background-color: rgb(82, 204, 167);
    color: #FAFAFA;
}

.vote__container { 
    display: flex;
    flex: 1 1;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

