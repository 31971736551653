.lobby__players {
    width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.lobby__players h2 {
    text-align: center;
    margin: 15px 0;
}

.lobby__info {
    text-align: center;
}