@import url('https://fonts.googleapis.com/css?family=Arvo|Open+Sans&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  color: #FAFAFA;
  font-family: 'Arvo', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  min-height: 100vh;
}

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 85%;
}

.button--default {
  width: 100%;
  max-width: 500px;
  margin: 15px 0;
  height: 55px;
  border: none;
  background-color: #2C2C2C;
  border-radius: 4px;
  color: #FAFAFA;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
}

button {
  outline: none;
}

.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  font-size: 32px;
}

.title h2 {
  margin: 0;
}

h2, h3 {
  text-align: center;
}

.title h1 {
  font-size: 70px;
  margin: 0;
  letter-spacing: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 85%;
  margin-top: 10px;
  flex: 1;
}

input {
  border: none;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  max-width: 500px;
  margin: 5px 0 15px 0;
  color: #2C2C2C;
  padding: 0 10px;
  font-size: 2rem;
  text-align: center;
}

select {
  border: none;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  margin: 5px 0 15px 0;
  color: #2C2C2C;
  padding: 0 10px;
  font-size: 2rem;
  text-align: center;
}

option {
  color: #2C2C2C;
  font-family: 'Arvo', serif;
}

input:focus {
  outline-width: 0;
}

label {
  font-size: 1.2rem;
}

.--join {
  background-color: #478BBC;
}

.--lobby {
  background-color: #A266EE;
}

.--results {
  background-color: #A266EE;
}

.--round {
  background-color: #BC4771;
}

.--vote {
  background-color: #478BBC;
}

.back {
  cursor: pointer;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 30px solid #FAFAFA;
  position: absolute;
  top: 15px;
  left: 15px;
}

.alert {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background-color: #FAFAFA;
  color: #000000;
  height: 75px;
  width: 90%;
  margin: auto 0;
}

.max-sm {
  max-width: 480px;
}

.results__form-group {
  margin: 0 10px;
}